import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Report`}</h1>
    <p>{`The `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{` is generated in real time, and is distinct from `}<a parentName="p" {...{
        "href": "/notebooks",
        "title": "notebooks"
      }}>{`notebooks`}</a>{` which are run on the data `}<a parentName="p" {...{
        "href": "/export",
        "title": "export"
      }}>{`export`}</a>{``}</p>
    <p>{``}<a parentName="p" {...{
        "href": "/notebooks",
        "title": "notebooks"
      }}>{`notebooks`}</a>{` are (probably forever) higher fidelity and (definitely forever) research oriented, though analysis methods pioneered in the `}<a parentName="p" {...{
        "href": "/notebooks",
        "title": "notebooks"
      }}>{`notebooks`}</a>{` will be turned back around into the real-time `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{` as we are confident they have matured`}</p>
    <p>{`Example report: `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{` `}<a parentName="p" {...{
        "href": "https://pol.is/report/r3epuappndxdy7dwtvwpb"
      }}>{`https://pol.is/report/r3epuappndxdy7dwtvwpb`}</a></p>
    <p>{`Example report: `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{` -`}<a parentName="p" {...{
        "href": "http://pol.is/report/r8zjztmxvc5x82pwer6my"
      }}>{` Pol.is/report/r8zjztmxvc5x82pwer6my`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      